<template>
  <div>
    <Loader v-if="isLoading"></Loader>
    <section class="section_pt section_pb" v-else>
      <div class="container container_wide">
        <div class="container__mobile">
          <!-- Not found : BEGIN -->
          <EmptyState
            v-show="notFound"
            :subtitle="$t('statistics.no_statistics')"
          ></EmptyState>
          <!-- Not found : END -->
          <!-- Server error : BEGIN -->
          <EmptyState
            v-show="serverError"
            :subtitle="$t('statistics.loading_error')"
          >
            <div class="section__group">
              <Button
                variant="secondary"
                :text="$t('general.retry')"
                size="lg"
                icon-left="undo"
                @click.prevent="retryLoadStatistics()"
              ></Button>
            </div>
          </EmptyState>
          <!-- Server error : END -->
          <Row vertical-gutters v-show="!notFound && !serverError">
            <Col>
              <div class="block block_wide" style="max-width: 1312px;">
                <h2 class="h2_mb" v-html="$t('statistics.participants_title')"></h2>
                <Row class="rr-grid_40" justify="space-between" style="flex-direction: row-reverse">
                  <Col class="block__item" :size="{lg: 'auto'}">
                    <div class="d-none d-lg-block" style="min-width: 360px;"></div>
                    <ParticipationChart
                      :title="null"
                      :data="[
                        { title: $t('statistics.registered'), value: statisticsByParticipants.registered },
                        { title: $t('statistics.started'), value: statisticsByParticipants.started },
                        { title: $t('statistics.finished'), value: statisticsByParticipants.finished },
                      ]"
                    ></ParticipationChart>
                  </Col>
                  <Col class="block__item" :size="{lg: 'stretch'}">
                    <div class="table-container">
                      <table class="table table_default table_striped">
                        <colgroup>
                          <col width="130">
                        </colgroup>
                        <thead class="table__header">
                        <tr>
                          <th></th>
                          <th class="text-center" v-if="statisticsByStatus.registered.male">{{ $tc('general.man', 2) }}</th>
                          <th class="text-center" v-if="statisticsByStatus.registered.female">{{ $tc('general.woman', 2) }}</th>
                          <th class="text-center" v-if="statisticsByStatus.registered.teams">{{ $t('general.teams') }}</th>
                          <th class="text-center">{{ $t('general.total') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                          class="text-nowrap"
                          v-for="(statistics, key) in statisticsByStatus"
                          :key="key"
                        >
                          <td class="text-sm">
                            <b>{{ statistics.title }}</b>
                          </td>
                          <td
                            class="text-center text-lg font-monospace"
                            v-if="statisticsByStatus.registered.male"
                          >
                            {{ statistics.male }}
                            <div class="text-sm color-muted" v-if="statistics.malePercent >= 0">
                              {{ statistics.malePercent || 0 }}%
                            </div>
                          </td>
                          <td
                            class="text-center text-lg font-monospace"
                            v-if="statisticsByStatus.registered.female"
                          >
                            {{ statistics.female }}
                            <div class="text-sm color-muted" v-if="statistics.femalePercent >= 0">
                              {{ statistics.femalePercent || 0 }}%
                            </div>
                          </td>
                          <td
                            class="text-center text-lg font-monospace"
                            v-if="statisticsByStatus.registered.teams"
                          >
                            {{ statistics.teams }}
                            <div class="text-sm color-muted">
                              {{ statistics.teamsMembers }}&nbsp;{{ $t('general.mbr') }}
                            </div>
                          </td>
                          <td class="text-center text-lg font-monospace">
                            {{ statistics.total }}
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
                <Row
                  v-if="showExtendedStatistic"
                  class="rr-grid_40 extended-statistic">
                  <Col>
                    <ExtendedStatistic

                      :genderRaceStatusStatistics="genderRaceStatusStatistics"
                      :teamRaceStatusStatistics="teamRaceStatusStatistics"/>
                  </Col>
                </Row>
                <br>
              </div>
            </Col>

            <Col v-if="statisticsByRaces.length">
              <div class="block block_wide" style="max-width: 1312px;">
                <Row>
                  <Col size="stretch" style="min-width: 0;">
                    <h2 v-html="$t('statistics.races_title')"></h2>
                  </Col>
                  <Col size="auto">
                    <label style="user-select: none;">
                      <Icon name="line-chart" size="xl"></Icon>
                    </label>&nbsp;&nbsp;
                    <RrSwitch
                      id="showCheckpoints"
                      v-model="showCharts"
                    ></RrSwitch>
                  </Col>
                </Row>
                <Row class="section rr-grid_40">
                  <div></div>
                  <Col
                    class="section__item"
                    v-for="race in statisticsByRaces"
                    :key="race.id"
                    :size="showCharts ? null : { xl: '12' }"
                  >
                    <div></div>
                    <div class="section__item">
                      <h3 class="h3_mb">{{ race.name }}</h3>
                    </div>
                    <div class="section__item_sm">
                      <div
                        class="charts"
                        v-show="showCharts"
                      >
                        <div class="charts__legend">
                          <div class="legend">
                            <div class="legend__item">
                              <div class="legend__icon" style="background-color: #36C597;"></div>
                              <div class="legend__label">{{ $tc('general.man', 2) }}</div>
                            </div>
                            <div class="legend__item">
                              <div class="legend__icon" style="background-color: #81D376;"></div>
                              <div class="legend__label">{{ $tc('general.woman', 2) }}</div>
                            </div>
                            <div class="legend__item">
                              <div class="legend__icon" style="background-color: #F2532C;"></div>
                              <div class="legend__label">{{ $t('general.all') }}</div>
                            </div>
                          </div>
                        </div>
                        <div class="charts__content">
                          <div class="js-charts-slider block__fullwidth">
                            <Row
                              class="swiper-wrapper"
                              :noWrap="useSliders"
                              :style="useSliders && 'margin: 0;'"
                            >
                              <Col class="swiper-slide" :size="{default: '24', md: '8'}">
                                <DistanceChart
                                  class="font-monospace"
                                  ref="distanceChart"
                                  :title="$t('table.avg_time_title')"
                                  :data="[
                                    { title: $tc('general.man', 2), value: race.male.avgTime },
                                    { title: $tc('general.woman', 2), value: race.female.avgTime },
                                    { title: $t('general.all'), value: race.all.avgTime },
                                  ]"
                                  :yAxisLabelFormatter="formatTime"
                                ></DistanceChart>
                              </Col>
                              <Col class="swiper-slide" :size="{default: '24', md: '8'}">
                                <DistanceChart
                                  class="font-monospace"
                                  ref="distanceChart"
                                  :title="`${$t('table.avg_time_title')} (${$t('general.kmMin')})`"
                                  :data="[
                                    { title: $tc('general.man', 2), value: race.male.avgPace },
                                    { title: $tc('general.woman', 2), value: race.female.avgPace },
                                    { title: $t('general.all'), value: race.all.avgPace },
                                  ]"
                                  :yAxisLabelFormatter="formatTime"
                                ></DistanceChart>
                              </Col>
                              <Col class="swiper-slide" :size="{default: '24', md: '8'}">
                                <DistanceChart
                                  class="font-monospace"
                                  ref="distanceChart"
                                  :title="`${$t('table.avg_speed_title')} (${$t('general.kmPerHour')})`"
                                  :data="[
                                    { title: $tc('general.man', 2), value: race.male.avgSpeed },
                                    { title: $tc('general.woman', 2), value: race.female.avgSpeed },
                                    { title: $t('general.all'), value: race.all.avgSpeed },
                                  ]"
                                  :yAxisLabelFormatter="formatSpeedAxisLabel"
                                ></DistanceChart>
                              </Col>
                            </Row>
                            <div class="swiper-pagination" v-show="useSliders"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="section__item_sm">
                      <div class="table-container">
                        <table class="table table_default table_striped">
                          <thead class="table__header">
                          <tr>
                            <th></th>
                            <th class="text-center" style="min-width: 100px;">{{ $t('table.avg_time_title') }}</th>
                            <th class="text-center" style="min-width: 100px;">{{ $t('table.avg_pace_title') }}</th>
                            <th class="text-center" style="min-width: 100px;">{{ $t('table.avg_speed_title') }}</th>
                            <th class="text-center" style="min-width: 100px;">{{ $t('table.total_distance_title') }}</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr
                            v-for="(statistics, j) in [
                              { name: $tc('general.man', 2), data: race.male },
                              { name: $tc('general.woman', 2), data: race.female },
                              { name: $t('general.all'), data: race.all },
                            ]"
                            :key="j"
                            class="text-nowrap"
                          >
                            <td class="text-sm">
                              <b>{{ statistics.name }}</b>
                            </td>
                            <td class="text-center text-lg font-monospace">
                              {{ statistics.data.avgTimeFormatted || '--'  }}
                            </td>
                            <td class="text-center text-lg font-monospace" v-html="formatResultWithUnits(statistics.data.avgPaceFormatted) || '--'">
                            </td>
                            <td class="text-center text-lg font-monospace" v-html="formatResultWithUnits(statistics.data.avgSpeedFormatted) || '--'">
                            </td>
                            <td class="text-center text-lg font-monospace" v-html="formatResultWithUnits(statistics.data.totalDistance) || '--'">
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Col>
                </Row>
                <br>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  </div>

</template>

<script>
import resultsApi from '@/services/results-api';
import { formatPaceText } from '@/utils';
import { formatTime } from '@/assets/js/helpers';
import { mapGetters } from 'vuex';
import Swiper, { Pagination } from 'swiper';
import RrSwitch from '@/components/Switch/Switch.vue';
import EmptyState from '@/components/EmptyState/EmptyState.vue';
import ParticipationChart from './Charts/ParticipationChart.vue';
import DistanceChart from './Charts/DistanceChart.vue';
import ExtendedStatistic from './ExtendedStatistic/ExtendedStatistic.vue';

Swiper.use([Pagination]);

export default {
  name: 'Statistics',
  components: {
    RrSwitch,
    ParticipationChart,
    DistanceChart,
    EmptyState,
    ExtendedStatistic,
  },
  props: {
    raceId: {
      type: String,
    },
  },
  data() {
    return {
      statisticsByParticipants: [],
      statisticsByStatus: {
        registered: {},
        started: {},
        finished: {},
      },
      raceInfo: {},
      genderRaceStatusStatistics: [],
      teamRaceStatusStatistics: {},
      statisticsByRaces: [],
      isLoading: false,
      showCharts: false,
      useSliders: window.innerWidth < 768,
      notFound: false,
      serverError: false,
    };
  },
  computed: {
    ...mapGetters('event', ['event']),
    showExtendedStatistic() {
      return this.genderRaceStatusStatistics.length && this.raceInfo.showExtendedResultStatistics;
    },
  },
  methods: {
    async setRaceInfo() {
      const { data } = await resultsApi.getRaceInfo({ raceId: this.raceId });

      this.raceInfo = data;
    },
    formatTime,
    formatSpeedAxisLabel(val) {
      return (+val).toFixed(2);
    },
    formatResultWithUnits: formatPaceText,
    getTimeInSeconds(timeString) {
      if (!timeString) {
        return 0;
      }
      const parts = timeString.split(':');
      if (parts[2]) {
        return +parts[0] * 3600 + +parts[1] * 60 + +parts[2];
      }
      return +parts[0] * 60 + +parts[1];
    },
    retryLoadStatistics() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      setTimeout(this.loadStatistics, 3000);
    },
    loadStatistics() {
      this.isLoading = true;
      let responseStatus = null;

      resultsApi.getEventStatistics({
        eventCode: this.event.code,
      })
        .then((response) => {
          this.setData(response.data);
          responseStatus = response.status;
          setTimeout(() => {
            const sliders = document.querySelectorAll('.js-charts-slider');
            sliders.forEach(this.createChartsSlider);
          }, 100);
        })
        .catch((error) => {
          responseStatus = error.response.status;
          this.notFound = responseStatus === 404;
        })
        .finally(() => {
          this.serverError = responseStatus !== 200 && responseStatus !== 404;
          this.isLoading = false;
        });
    },
    setData(data) {
      if (!data) {
        return;
      }

      this.genderRaceStatusStatistics = data.genderRaceStatusStatistics;
      this.teamRaceStatusStatistics = data.teamRaceStatusStatistics;

      this.statisticsByRaces = (data.raceStatistics || []).map((race) => ({
        id: race.id,
        name: race.name,
        male: {
          avgTime: this.getTimeInSeconds(race.malesAvgTimeResult),
          avgTimeFormatted: race.malesAvgTimeResult,
          avgPace: this.getTimeInSeconds(race.malesAvgPace?.split(' ')[0]),
          avgPaceFormatted: race.malesAvgPace,
          avgSpeed: race.malesAvgSpeed?.split(' ')[0],
          avgSpeedFormatted: race.malesAvgSpeed,
        },
        female: {
          avgTime: this.getTimeInSeconds(race.femalesAvgTimeResult),
          avgTimeFormatted: race.femalesAvgTimeResult,
          avgPace: this.getTimeInSeconds(race.femalesAvgPace?.split(' ')[0]),
          avgPaceFormatted: race.femalesAvgPace,
          avgSpeed: race.femalesAvgSpeed?.split(' ')[0],
          avgSpeedFormatted: race.femalesAvgSpeed,
        },
        all: {
          avgTime: this.getTimeInSeconds(race.avgTimeResult),
          avgTimeFormatted: race.avgTimeResult,
          avgPace: this.getTimeInSeconds(race.avgPace?.split(' ')[0]),
          avgPaceFormatted: race.avgPace,
          avgSpeed: race.avgSpeed?.split(' ')[0],
          avgSpeedFormatted: race.avgSpeed,
          totalDistance: race.totalDistance
            ? `${Math.round(race.totalDistance / 1000)} ${this.$t('general.km')}`
            : null,
        },
      }));

      const byStatus = {
        registered: {
          title: this.$t('statistics.registered'),
          male: data.numberOfMaleParticipants || 0,
          female: data.numberOfFemaleParticipants || 0,
          teams: data.numberOfTeams || 0,
          teamsMembers: data.numberOfTeamMembers || 0,
        },
        started: {
          title: this.$t('statistics.started'),
          male: data.numberOfStartedMaleParticipants || 0,
          female: data.numberOfStartedFemaleParticipants || 0,
          teams: data.numberOfStartedTeams || 0,
          teamsMembers: data.numberOfStartedTeamMembers || 0,
        },
        finished: {
          title: this.$t('statistics.finished'),
          male: data.numberOfFinishedMaleParticipants || 0,
          female: data.numberOfFinishedFemaleParticipants || 0,
          teams: data.numberOfFinishedTeams || 0,
          teamsMembers: data.numberOfFinishedTeamMembers || 0,
        },
      };

      Object.keys(byStatus)
        .forEach((key) => {
          const statistics = byStatus[key];
          statistics.total = statistics.male + statistics.female;

          if (!statistics.total) {
            statistics.malePercent = 0;
            statistics.femalePercent = 0;
          } else {
            statistics.malePercent = Math.round((statistics.male / statistics.total) * 100);
            statistics.femalePercent = 100 - statistics.malePercent;
          }
        });

      this.statisticsByStatus = byStatus;

      this.statisticsByParticipants = {
        registered: byStatus.registered.total,
        started: byStatus.started.total,
        finished: byStatus.finished.total,
      };
    },
    createChartsSlider(el) {
      if (!this.useSliders) {
        return;
      }
      // eslint-disable-next-line no-new
      new Swiper(el, {
        simulateTouch: false,
        spaceBetween: 40,
        speed: 200,
        watchSlidesProgress: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        breakpoints: {
          768: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
        },
      });
    },
  },
  watch: {
    showCharts(val) {
      if (val) {
        setTimeout(() => {
          const charts = this.$refs.distanceChart || [];
          charts.forEach((chart) => {
            chart.refresh();
          });
        });
      }
    },
  },
  created() {
    this.loadStatistics();
    this.setRaceInfo();
  },
};
</script>

<style lang="scss" scoped>
@import "./Statistics";
</style>
