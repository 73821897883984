<template>
  <div class="chart">
    <div class="chart__container">
      <div
        ref="canvas"
        class="chart__canvas"
      ></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts/core';
import { BarChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent, GridComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use(
  [TitleComponent, TooltipComponent, GridComponent, BarChart, CanvasRenderer],
);

export default {
  name: 'DistanceChart',
  props: {
    title: String,
    data: {
      type: Array,
      default: () => [],
    },
    yAxisLabelFormatter: Function,
  },
  data() {
    return {
      chart: null,
      palette: [
        '#36C597',
        '#81D376',
        '#F2532C',
      ],
    };
  },
  methods: {
    create() {
      const data = this.data.map(({ title, value }, i) => ({
        name: title,
        value: typeof value === 'string'
          ? +(value.replace(',', '.'))
          : value,
        itemStyle: {
          color: this.palette[i],
        },
      }));

      const valuesLengths = data.map((o) => (this.yAxisLabelFormatter ? this.yAxisLabelFormatter(o.value) : o.value).length);
      const maxValueLength = Math.max.apply(null, valuesLengths);

      const option = {
        grid: {
          top: 10,
          right: 0,
          bottom: 24,
          left: (maxValueLength + 1) * 8,
        },
        textStyle: {
          fontFamily: 'Montserrat',
          fontSize: 12,
          lineHeight: 18,
          color: 'rgba(1, 1, 1, 0.45)',
        },
        xAxis: {
          type: 'category',
          data: data.map((o) => o.name),
          name: this.title,
          nameLocation: 'center',
          nameGap: 5,
          nameTextStyle: {
            fontWeight: 600,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(1, 1, 1, 0.45)',
            },
          },
        },
        yAxis: {
          type: 'value',
          splitNumber: 3,
          splitLine: {
            lineStyle: {
              color: 'rgba(1, 1, 1, 0.15)',
            },
          },
          axisLabel: {
            formatter: (value) => (this.yAxisLabelFormatter
              ? this.yAxisLabelFormatter(value)
              : value),
          },
        },
        series: [{
          type: 'bar',
          silent: true,
          data,
        }],
        animationDuration: 500,
      };
      const chart = echarts.init(this.$refs.canvas, null, {
        renderer: 'svg',
      });
      chart.setOption(option);
      window.addEventListener('resize', chart.resize);
      this.chart = chart;
    },
    refresh() {
      if (this.chart) {
        this.chart.resize();
      }
    },
  },
  mounted() {
    this.create();
  },
  beforeDestroy() {
    echarts.dispose(this.$refs.canvas);
  },
};
</script>

<style lang="scss" scoped>
@import "./Charts.scss";

.chart {
  padding: 0 20px;
  animation: rr-fade-in 1s;

  @media (min-width: breakpoint(md)) {
    padding: 0;
  }
}
</style>
