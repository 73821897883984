<template>
  <div class="extended-statistic">
    <table class="table table_default table_striped">
      <colgroup>
        <col width="210">
      </colgroup>
      <thead class="table__header">
      <tr>
        <th></th>
        <th
          v-html="$t('statistics.extDNS')"
          class="text-center"></th>
        <th
          v-html="$t('statistics.extDNF')"
          class="text-center"></th>
        <th
          v-html="$t('statistics.extLAP')"
          class="text-center"></th>
        <th
          v-html="$t('statistics.extDSQ')"
          class="text-center"></th>
        <th
          v-html="$t('statistics.extNC')"
          class="text-center"></th>
      </tr>
      </thead>
      <tbody>
        <tr
          v-for="(stats, gender) in raceGenderStatistic"
          :key="gender">
          <td class="text-sm">
            <b>{{ gender == 0 ? $t('statistics.men') : $t('statistics.women') }}</b>
          </td>
          <td
            v-for="(stat, index) in stats"
            :key="index"
            class="text-center text-lg">
            {{ stat }}
          </td>
        </tr>
        <tr>
          <td class="text-sm">
            <b>{{ $t('statistics.teams') }}</b>
          </td>
          <td
            v-for="(stat, index) in teamStatistic"
            :key="index"
            class="text-center text-lg">
            {{ stat }}
          </td>
        </tr>
        <tr class="extended-statistic__total">
          <td class="text-sm">
            <b>{{ $t('statistics.totalParticipants') }}</b>
          </td>
          <td
            v-for="(stat, index) in totalStatistic"
            :key="index"
            class="text-center text-lg">
            {{ stat }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
const colsGenderOrder = ['numberOfDnsParticipants', 'numberOfDnfParticipants', 'numberOfLapParticipants', 'numberOfDsqParticipants', 'numberOfNcParticipants'];
const colsTeamsOrder = ['numberOfDnsTeams', 'numberOfDnfTeams', 'numberOfLapTeams', 'numberOfDsqTeams', 'numberOfNcTeams'];

export default {
  name: 'ExtendedStatistic',
  props: {
    genderRaceStatusStatistics: {
      type: Array,
      default: () => [],
    },
    teamRaceStatusStatistics: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    raceGenderStatistic() {
      return this.genderRaceStatusStatistics.reduce((acc, item) => {
        const { gender, ...rest } = item;
        acc[gender] = colsGenderOrder.map((key) => rest[key]);

        return acc;
      }, {});
    },
    teamStatistic() {
      return colsTeamsOrder.map((key) => this.teamRaceStatusStatistics[key]);
    },
    totalStatistic() {
      return Object.values(this.raceGenderStatistic).reduce((acc, item) => {
        item.forEach((val, index) => {
          acc[index] = (acc[index] || 0) + val;
        });

        return acc;
      }, []);
    },
  },
};
</script>

<style>
@import './ExtendedStatistic.scss';
</style>
