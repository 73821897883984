<template>
  <div class="chart">
    <div class="chart__container">
      <div
        ref="canvas"
        class="chart__canvas"
      ></div>
    </div>
    <div class="chart__title" v-if="title">{{ title }}</div>
  </div>
</template>

<script>
import * as echarts from 'echarts/core';
import { FunnelChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent, GridComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use(
  [TitleComponent, TooltipComponent, GridComponent, FunnelChart, CanvasRenderer],
);

export default {
  name: 'ParticipationChart',
  props: {
    title: String,
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chart: null,
      palette: [
        '#36C597',
        '#81D376',
        '#F2532C',
      ],
    };
  },
  methods: {
    create() {
      const data = this.data.map(({ title, value }, i) => ({
        name: title,
        value: typeof value === 'string'
          ? +(value.replace(',', '.'))
          : value,
        itemStyle: {
          color: this.palette[i],
        },
      }));

      const option = {
        textStyle: {
          fontFamily: 'Montserrat',
          fontSize: 12,
          lineHeight: 18,
          color: '#FFFFFF',
        },
        series: [{
          type: 'funnel',
          label: {
            position: 'inside',
            formatter: '{b}\n\n{c}',
          },
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          silent: true,
          data,
        }],
        animationDuration: 500,
      };
      const chart = echarts.init(this.$refs.canvas, null, {
        renderer: 'svg',
      });
      chart.setOption(option);
      window.addEventListener('resize', chart.resize);
      this.chart = chart;
    },
    refresh() {
      if (this.chart) {
        this.chart.resize();
      }
    },
  },
  mounted() {
    this.create();
  },
  beforeDestroy() {
    echarts.dispose(this.$refs.canvas);
  },
};
</script>

<style lang="scss" scoped>
@import "./Charts.scss";

.chart {
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 0;

  @media (min-width: breakpoint(lg)) {
    padding: 0;
  }
}
</style>
